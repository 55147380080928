<template>
  <onboarding-layout :pet-type="petType">
    <h1 class="h2 mb-2">
      Open je mailbox om je account te activeren.
    </h1>

    <p class="mb-1 color-text">
      We hebben zojuist een link gestuurd naar {{ userEmail }}.
    </p>

    <p class="mb-3 color-text">
      Heb je geen link ontvangen? Controleer ook je spam en/of reclame map.
    </p>

    <Button button-type="button" @click="backToLogin">
      Ga naar inloggen
    </Button>
  </onboarding-layout>
</template>

<script>
import OnboardingLayout from './OnboardingLayout.vue';
import Button from '../../components/Button.vue';

export default {
  components: {
    Button,
    OnboardingLayout,
  },

  data() {
    return {
      petType: 'dog',
    };
  },

  created() {
    if (this.$store.state.onboarding.petType) {
      this.petType = this.$store.state.onboarding.petType;
    }

    this.$store.commit('resetLayout', {
      showHasMail: true,
      showCta: false,
      progress: null,
    });
  },

  mounted() {
    this.$gtm.trackEvent({
      event: 'account-created',
      category: 'Onboarding',
      action: 'account_created',
    });

    this.$gtm.trackEvent({
      event: 'onboarding',
      category: 'Onboarding',
      action: 'step',
      label: 'complete',
    });
  },

  methods: {
    backToLogin() {
      this.$router.push({
        name: 'Login',
      });
    },
  },

  computed: {
    userEmail() {
      return this.$store.state.onboarding.email;
    },

    avatar() {
      return this.$store.state.onboarding.avatar;
    },
  },
};
</script>
